

const storeSession = (session) => {
    localStorage.setItem('@session', session);
}

const getSession = () => {
    return localStorage.getItem('@session');
}

const removeSession = (session) => {
    localStorage.removeItem('@session');
}

export {
    storeSession,
    getSession,
    removeSession,
}