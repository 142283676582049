

const ENV = {PROD: 0, DEV: 1}

const env = ENV.PROD;

const DOMAIN = (env === ENV.PROD) ? 
    'elb-api.metacanvas.app' : 
    '127.0.0.1:8000';

const CONN = {
    URL: (env === ENV.PROD) ? `https://${DOMAIN}/v1/canvas` : `http://${DOMAIN}/v1/canvas`,
    WS:  (env === ENV.PROD) ? `wss://${DOMAIN}/v1/canvas/ws/` : `ws://${DOMAIN}/v1/canvas/ws/`,
};

export default CONN;