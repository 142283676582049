import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Canvas, UIPanel } from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';

import LoginPage from './Pages/Login';
import RoomPage from './Pages/Room';
import Loader from './Pages/Loader';
import AdminPanel from './Pages/Admin';


import { BrowserRouter, Routes, Route } from "react-router-dom";


const Switcher = ({ loaded, listCb }) => {

  return loaded ? (
    <Routes>
      <Route path="/"    element={<LoginPage />} />
      <Route path="room" element={<RoomPage />} />
      <Route path="room/:roomId" element={<UIPanel admin={false} changeWidth={listCb.setWidth} goBack={listCb.goBack} goFor={listCb.goFor} />} />
      <Route path="admin" element={
        <>
          <UIPanel admin={true} changeWidth={listCb.setWidth} goBack={listCb.goBack} goFor={listCb.goFor} />
          <AdminPanel />
        </>
      } />
    </Routes>
  ) : null;
}

const MixedRender = () => {
  const [progress, setProgress] = useState(0);
  const [listCb, set__listCb] = useState(null);

  const callbacksHandlers = (handlers) => {
    set__listCb(handlers);
  }

  return (
    <>
      <Switcher loaded={progress === 1} listCb={listCb} />
      <Loader   progress={progress} />
      <Canvas  setCallbacks={callbacksHandlers} setProgress={setProgress} />
    </>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
     <BrowserRouter>
        <MixedRender />
      </BrowserRouter>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
