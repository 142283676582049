

import { sendPointUpdates, sendBlockUpdate, requestHistory, sendStateUpdate, informLayerDeny, goForwardNetwork, goBackNetwork, } from '../Network/Api';
import { decodeBlock } from '../Network/Encoder';
import sceneParams from './Settings';

/*
    To Draw on canvas, we are using multiple layers.
    Live layer is where all strokes render first.


*/


class DrawLayer {

    constructor() {
        this.stats = {
            tilesVisible: 0,
        }
        this.tilingPool = {}
        this.updatedBlock = {}
        this.needSetup = true;
        this.networkData = null;
    }

    calculateTileLimits(p5, deltaSize) {
        let xMin = Math.floor(Math.max(0, (-sceneParams.position.x / sceneParams.zoom) / deltaSize));
        let yMin = Math.floor(Math.max(0, (-sceneParams.position.y / sceneParams.zoom) / deltaSize));
        let xMax = Math.min(sceneParams.tiles.x * sceneParams.tiles.scale / deltaSize, xMin + 1 + (p5.windowWidth  / sceneParams.zoom / deltaSize));
        let yMax = Math.min(sceneParams.tiles.y * sceneParams.tiles.scale / deltaSize, yMin + 1 + (p5.windowHeight / sceneParams.zoom / deltaSize));
        return { xMin, yMin, xMax, yMax };
    }

    decodeBlock(data, x, y) {
        if (!this.networkData) {
            this.networkData = {};
        }
        if (!this.networkData[x]) {
            this.networkData[x] = {};
        }
        this.networkData[x][y] = data;
    }

    performDataDecode(p5) {
        for (let x in this.networkData) {
            for (let y in this.networkData[x]) {
                this.tilingPool[x][y] = decodeBlock(p5, this.tilingPool[x][y], this.networkData[x][y]);
            }
        }
    }

    draw(p5) {
        let { xMin, yMin, xMax, yMax } = this.calculateTileLimits(p5, sceneParams.blockSize);
        let tCount = 0;
        if (!!this.networkData) {
            this.performDataDecode(p5);
        }
        for (let x = xMin; x < xMax; x++) {
          for (let y = yMin; y < yMax; y++) {
            p5.noSmooth();
            p5.image(
              this.tilingPool[x][y], 
              x * sceneParams.blockSize + (sceneParams.position.x / sceneParams.zoom), 
              y * sceneParams.blockSize + (sceneParams.position.y / sceneParams.zoom),
            );
            tCount++;
          }
        }
        this.networkData = null;
        this.stats.tilesVisible = tCount;
    }

    setup(p5) {
        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            this.tilingPool[x] = {};
            this.updatedBlock[x] = {};
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                this.tilingPool[x][y] = p5.createImage(sceneParams.blockSize, sceneParams.blockSize);
                this.updatedBlock[x][y] = false;
            }
        }
        this.needSetup = false;
    }

}

class UpdatableLayer extends DrawLayer {

    prevPosCur = null;
    history = [];
    cursor = 0;
    last = null;
    maxLayers = 10;
    layers = [];
    lastSent = 0;
    needResend = false;
    needResendLatest = false;
    timedInt = 0;
    prevPos = null;
    readyReSend = false;
    changedSmth = false;

    setupNewCanvasState(p5) {
        this.localLayer   = this.makeLayer(p5);
        this.networkLayer = this.makeLayer(p5);
    }

    adjustHistoryNet(time, user, deny) {
        // for (let i = 0; i < this.layers.length; i++) {
        //     if (!this.layers[i].own && this.layers[i].user === user && this.layers[i].time === time) {
        //         this.layers[i].enable = !deny;
        //     }
        // }
    }

    resetLocal(p5) {
        if (this.needResend || this.needResendLatest) return;
        this.localLayer = this.makeLayer(p5);
    }

    bake(p5, img, sx, sy, dx, dy) {

        if (this.lastSent + 1000 <= Date.now()) {
            this.addLayer(p5);
            this.lastSent = Date.now();
        }
        
        // let r = {};
        // if (this.last === null || this.last + 1 < Date.now()/1000) {
        //     this.last = Date.now()/1000;
        //     for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
        //         r[x] = {};
        //         for (let y = 0; y < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
        //             r[x][y] = p5.createImage(96, 96);
        //             r[x][y].set(0, 0, this.tilingPool[x][y].get());
        //         }
        //     }
        //     this.history.push(r);
        //     this.cursor = 0;
        // }

        
        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                this.layers[this.layers.length - this.cursor - 1].data[x][y].blend(
                    img, 
                    0, 0, 
                    img.width, img.height, 
                    sx - (sceneParams.blockSize * x), sy - (sceneParams.blockSize * y), 
                    dx, dy,
                    p5.BLEND,
                  );
            }
        }

        this.prevPosCur = {
            x: p5.mouseX,
            y: p5.mouseY
        };

        this.needResend = true;

    }

    jumpHistoryBack() {
        // if (this.layers.length > 1 && this.cursor + 1 < this.layers.length) {
        //     this.cursor++;
        //     if (!this.layers[this.layers.length - this.cursor].own) {
        //         this.jumpHistoryBack();
        //     } else {
        //         this.layers[this.layers.length - this.cursor].enable = false;
        //         informLayerDeny(this.layers[this.layers.length - this.cursor], true);
        //         this.needResendLatest = true;
        //     }
        // }
    }
    
    jumpHistoryFor() {
        // if (this.layers.length > 0 && this.cursor > 0) {
        //     this.cursor--;
        //     if (!this.layers[this.layers.length - 1 - this.cursor].own) {
        //         this.jumpHistoryFor();
        //     } else {
        //         this.layers[this.layers.length - 1 - this.cursor].enable = true;
        //         informLayerDeny(this.layers[this.layers.length - 1 - this.cursor], false);
        //         this.needResendLatest = true;
        //     }
        // }
    }

    decodeNetworkLayerRoot(p5, layer) {
        let layer2 = this.makeLayer(p5);

        layer2.time = 0;
        layer2.own = false;
        layer2.user = 'root';

        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                layer2.data[x][y] = decodeBlock(p5, layer2.data[x][y], layer.d[x][y]);
            }
        }

        this.networkLayer = layer2;
        this.layers = [];

        // let toDelete = [];
        // for (let i = 1; i < this.layers.length; i++) {
        //     if (!this.layers[i].own) {
        //         toDelete.push(i);
        //     }
        // }

        // for (let indexDelete of toDelete) {
        //     this.layers.splice(indexDelete, 1);
        // }

    }
    decodeNetworkLayer(p5, layer, sender) {

        let layer2 = this.makeLayer(p5);
        layer2.time = layer.g;
        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                layer2.data[x][y] = decodeBlock(p5, layer2.data[x][y], layer.d[x][y]);
            }
        }
        layer2.own = false;
        layer2.user = sender;

        let toDelete = [];
        for (let i = 1; i < this.layers.length; i++) {
            if (this.layers[i].user === sender && !this.layers[i].own) {
                toDelete.push(i);
            }
        }

        for (let indexDelete of toDelete) {
            this.layers.splice(indexDelete, 1);
        }

        this.layers.push(layer2);
        this.sortLayers();
        // this.layersMergeTogether(p5);
    }

    sendLatest(p5) {
        // let ultraLayer = this.makeLayer(p5);
        // for (let layer of this.layers) {
        //     if (layer.enable) {
        //         ultraLayer.data = this.combineLayers(p5, ultraLayer.data, layer.data);
        //         ultraLayer.time = Math.max(layer.time, ultraLayer.time);
        //     }
        // }
        sendStateUpdate(p5, this.localLayer, sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize, sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize);
        // this.layers = [ultraLayer];
    }

    makeLayer(p5) {
        let newLayer = { data: {}, time: Date.now() / 1000, own: true, enable: true, user: 'self' };
        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            newLayer.data[x] = {};
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                newLayer.data[x][y] = p5.createImage(sceneParams.blockSize, sceneParams.blockSize);
            }
        }
        return newLayer;
    }

    sortLayers() {
        this.layers.sort((a, b) => a.time > b.time);
    }

    layersMergeTogether(p5) {
        // let counterLayer = {};
        // for (let layer of this.layers) {
        //     if (!counterLayer[layer.user]) {
        //         counterLayer[layer.user] = 1;
        //     } else {
        //         counterLayer[layer.user] ++;
        //     }
        // }
        // let toDelete = [];
        // for (let i = 1; i < this.layers.length; i++) {
        //     if (Math.max(...Object.values(counterLayer)) > this.maxLayers) {
        //         if (this.layers[i].enable) {
        //             this.layers[0].data = this.combineLayers(p5, this.layers[0].data, this.layers[i].data);
        //         }
        //         toDelete.push(i);
        //         counterLayer[this.layers[i].user]--;
        //     }
        // }
        // for (let indexDelete of toDelete) {
        //     this.layers.splice(indexDelete, 1);
        // }
    }

    // removeLocal() {
    //     this.layers.splice(1, 1);
    // }

    addLayer(p5) {
        let newLayer = this.makeLayer(p5);
        this.layers.push(newLayer);
        // this.layersMergeTogether(p5);
    }

    combineLayers(p5, layerBottom, layerTop) {
        for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                layerBottom[x][y].blend(
                    layerTop[x][y], 
                    0, 0, 
                    layerTop[x][y].width, layerTop[x][y].height, 
                    0, 0, 
                    layerTop[x][y].width, 
                    layerTop[x][y].height,
                    p5.BLEND
                );
            }
        }
        return layerBottom;
    }

    displayLayersCombined(p5) {

        let { xMin, yMin, xMax, yMax } = this.calculateTileLimits(p5, sceneParams.blockSize);

        for (let x = xMin; x < xMax; x++) {
            for (let y = yMin; y < yMax; y++) {
              p5.noSmooth();
              p5.image(
                  this.networkLayer.data[x][y], 
                  x * sceneParams.blockSize + (sceneParams.position.x / sceneParams.zoom), 
                  y * sceneParams.blockSize + (sceneParams.position.y / sceneParams.zoom),
              );
            }
        }

        for (let i = 0; i < this.layers.length; i++) {
            if (!this.layers[i].enable) continue;
            let tCount = 0;
            if (!!this.networkData) {
                this.performDataDecode(p5);
            }
            for (let x = xMin; x < xMax; x++) {
              for (let y = yMin; y < yMax; y++) {
                p5.noSmooth();
                p5.image(
                    this.layers[i].data[x][y], 
                    x * sceneParams.blockSize + (sceneParams.position.x / sceneParams.zoom), 
                    y * sceneParams.blockSize + (sceneParams.position.y / sceneParams.zoom),
                );
                tCount++;
              }
            }
            this.networkData = null;
            this.stats.tilesVisible = tCount;
        }

        for (let x = xMin; x < xMax; x++) {
            for (let y = yMin; y < yMax; y++) {
              p5.noSmooth();
              p5.image(
                  this.localLayer.data[x][y], 
                  x * sceneParams.blockSize + (sceneParams.position.x / sceneParams.zoom), 
                  y * sceneParams.blockSize + (sceneParams.position.y / sceneParams.zoom),
              );
            }
        }
    }

    timedPos(pos) {
        if (isNaN(pos.x) && isNaN(pos.y)) {
            this.timedInt++;
        } else {
            this.timedInt = 0;
            this.prevPos = pos;
        }
    }

    drawDrop(p5, pos, brush) {


        const getBlock = (pXY) => Math.floor(pXY / sceneParams.blockSize);

        const calcPoolBlock = (pX, pY) => {
          
          if (isNaN(pX) || isNaN(pY)) return;
          if ( pX < 0 || pX >= sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize) return;
          if ( pY < 0 || pY >= sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize) return;

        //   console.log(pX, sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize);
      
          this.updatedBlock[pX][pY] = true;
      
          p5.noSmooth();
          this.localLayer.data[pX][pY].blend(
            brush.drop, 
            0, 0, 
            1,1, 
            pos.x - (sceneParams.blockSize * pX), pos.y - (sceneParams.blockSize * pY), 
            1, 1,
            p5.BLEND,
          );

          this.localLayer.time = Date.now() / 1000;
        };
      
        if (pos.y < 0 || pos.x < 0) {
          return;
        }
      
        let dx = getBlock(pos.x);
        let dy = getBlock(pos.y);
      
        let t2x = getBlock(pos.x + brush.size);
        let t2y = getBlock(pos.y + brush.size);
      
        if (t2x === dx) {
          t2x = getBlock(pos.x - brush.size);
        }
        if (t2y === dy) {
          t2y = getBlock(pos.y - brush.size);
        }
      
        calcPoolBlock(dx, dy);
      
        if (t2x >= 0 && t2x !== dx && dy === t2y) {
          calcPoolBlock(t2x, dy);
        } else if (t2y >= 0 && t2y !== dy && dx === t2x) {
          calcPoolBlock(dx, t2y);
        } else if (t2y >= 0 && t2y !== dy && t2x >= 0 && t2x !== dx) {
          calcPoolBlock(t2x, dy);
          calcPoolBlock(dx, t2y);
          calcPoolBlock(t2x,t2y);
        }
    }

    // Draws brush strokes on a specific tiles
    drawOnCanvas(p5, pos, brush) {

        
        if (this.needSetup) return;
        this.timedPos(pos);

        let kColorBrushChannel = brush.texture;

        const getBlock = (pXY) => Math.floor(pXY / sceneParams.blockSize);

        const calcPoolBlock = (pX, pY) => {
          
          if (isNaN(pX) || isNaN(pY)) return;
          if ( pX < 0 || pX >= sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize) return;
          if ( pY < 0 || pY >= sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize) return;

        //   console.log(pX, sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize);
      
          this.updatedBlock[pX][pY] = true;
      
          p5.noSmooth();
          this.localLayer.data[pX][pY].blend(
            kColorBrushChannel, 
            0, 0, 
            kColorBrushChannel.width, kColorBrushChannel.height, 
            pos.x - (sceneParams.blockSize * pX) - (kColorBrushChannel.width / 2), pos.y - (sceneParams.blockSize * pY) - (kColorBrushChannel.height / 2), 
            kColorBrushChannel.width, kColorBrushChannel.height,
            (brush.erasing) ? p5.MULTIPLY : p5.BLEND,
          );

          this.localLayer.time = Date.now() / 1000;
        };
      
        if (pos.y < 0 || pos.x < 0) {
          return;
        }
      
        let dx = getBlock(pos.x);
        let dy = getBlock(pos.y);
      
        let t2x = getBlock(pos.x + brush.size);
        let t2y = getBlock(pos.y + brush.size);
      
        if (t2x === dx) {
          t2x = getBlock(pos.x - brush.size);
        }
        if (t2y === dy) {
          t2y = getBlock(pos.y - brush.size);
        }
      
        calcPoolBlock(dx, dy);
      
        if (t2x >= 0 && t2x !== dx && dy === t2y) {
          calcPoolBlock(t2x, dy);
        } else if (t2y >= 0 && t2y !== dy && dx === t2x) {
          calcPoolBlock(dx, t2y);
        } else if (t2y >= 0 && t2y !== dy && t2x >= 0 && t2x !== dx) {
          calcPoolBlock(t2x, dy);
          calcPoolBlock(dx, t2y);
          calcPoolBlock(t2x,t2y);
        }

        if (this.timedInt === 5) {
            this.drawDrop(p5, {y: this.prevPos.y + brush.size/2, x: this.prevPos.x}, brush);
            this.drawDrop(p5, {y: this.prevPos.y + brush.size/2 + 1, x: this.prevPos.x}, brush);
        }
        if (this.timedInt === 6) {
            let delatDrop = 1 + Math.round(Math.random());
            this.drawDrop(p5, {y: this.prevPos.y + brush.size/2 + delatDrop, x: this.prevPos.x}, brush);
            this.drawDrop(p5, {y: this.prevPos.y + brush.size/2 + delatDrop + Math.round(Math.random()), x: this.prevPos.x}, brush);
        }
    }

    runUpdateCycle(p5) {
        setInterval( () => {

            if (this.needResend) {
                sendBlockUpdate(
                    p5, 
                    this.localLayer, 
                    sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize, 
                    sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize
                );
            }

            if (this.readyReSend && this.changedSmth) {
                this.sendLatest(p5);
                this.changedSmth = false;
                this.readyReSend = false;
            } else if (this.readyReSend) {
                this.readyReSend = false;
            }


            this.needResend = false;
            this.needResendLatest = false;

            // for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
            //     for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
            //         if (this.updatedBlock[x][y]) {
            //             sendBlockUpdate(p5, this.tilingPool[x][y], x, y);
            //             this.updatedBlock[x][y] = false;
            //         }
            //     }
            // }
        }, 300);
    }

    makeDrawing(p5, brush) {

        // if (this.lastSent + 1000 <= Date.now()) {
        //     this.addLayer(p5);
        //     this.lastSent = Date.now();
        // }

        if (this.layers.length === 1) {
            this.addLayer(p5);
        }
        
        let r = {};
        if (this.last === null || this.last + 1 < Date.now()/1000) {
            this.last = Date.now()/1000;
            for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
                r[x] = {};
                for (let y = 0; y < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
                    r[x][y] = p5.createImage(96, 96);
                    r[x][y].set(0, 0, this.tilingPool[x][y].get());
                }
            }
            this.history.push(r);
            this.cursor = 0;
        }

        // Add poof animation
        if (sceneParams.poofDelay.current > sceneParams.poofDelay.max) {
            sceneParams.poofDelay.current = 0;
            sceneParams.poofs.push({
                color: p5.color(sceneParams.selectedColor.r, sceneParams.selectedColor.g, sceneParams.selectedColor.b),
                x: p5.mouseX / sceneParams.zoom - sceneParams.position.x,
                y: p5.mouseY / sceneParams.zoom - sceneParams.position.y,
                kf: 0,
            });
        }
    
        if (this.prevPosCur === null) {
            let pos =  {
                x: Math.floor((p5.mouseX - sceneParams.position.x) / sceneParams.zoom),
                y: Math.floor((p5.mouseY - sceneParams.position.y) / sceneParams.zoom),
            };
            this.drawOnCanvas(p5, pos, brush);
            // sendPointUpdates(pos, brush.size, sceneParams.selectedColor);
        } else {
            let calcR = Math.sqrt(Math.pow((this.prevPosCur.y - p5.mouseY) / sceneParams.zoom, 2) + Math.pow((this.prevPosCur.x - p5.mouseX) / sceneParams.zoom, 2));
            for (let i = 0; i <= calcR; i+=brush.size/2) {
        
                let calcK = i / calcR;
        
                let calcY = this.prevPosCur.y + ((p5.mouseY - this.prevPosCur.y) * calcK);
                let calcX = this.prevPosCur.x + ((p5.mouseX - this.prevPosCur.x) * calcK);
        
                let pos = {
                    x: Math.floor((calcX - sceneParams.position.x) / sceneParams.zoom),
                    y: Math.floor((calcY - sceneParams.position.y) / sceneParams.zoom),
                };
        
        
                this.drawOnCanvas(p5, pos, brush);
                // sendPointUpdates(pos, brush.size, sceneParams.selectedColor);
        
            }
        }

        this.prevPosCur = {
            x: p5.mouseX,
            y: p5.mouseY
        };

        this.changedSmth = true;
        this.needResend = true;

    }
}

class OverlayManager {

    constructor() {
        this.layers = {
            live: new UpdatableLayer(),
            //net: new UpdatableLayer(),
            //user: new DrawLayer(),
            //party: {},
        }
        this.brush = {
            size: 2,
            image: null,
            texture: null,
            erasing: false,
            drop: null,
        }
        this.textures = {
            brush_2: null,
            brush_4: null,
            brush_8: null,
            brush_16: null,
        }
        this.history_cursor = null;
        this.order = ['self'];
    }

    bake(p5, img, x, y, dx, dy) {
        this.layers.live.bake(p5, img, x, y, dx, dy);
    }

    netPolicyUpdate(policy) {
        this.layers.live.adjustHistoryNet(policy.time, policy.user, policy.deny);
    }

    resetLocal(p5) {
        this.layers.live.resetLocal(p5);
    }

    goBack() {
        goBackNetwork();
        // this.layers.live.removeLocal();
        //this.layers.live.jumpHistoryBack();
    }

    goFor() {
        goForwardNetwork();
        // this.layers.live.removeLocal();
        // this.layers.live.jumpHistoryFor();
    }

    nullPrevPosCur() {
        this.layers.live.prevPosCur = null;
    }

    drawStrokeLocal(p5) {
        this.layers.live.makeDrawing(p5, this.brush);
        this.order = this.order.filter((id) => id !== 'self');
        this.order.push('self');
    }

    pushTopLayer(p5, layer, user) {
       this.layers.live.decodeNetworkLayer(p5, layer, user);
    }

    netLayerInsert(p5, layer) {
        this.layers.live.decodeNetworkLayerRoot(p5, layer);
    }

    changeBrushSize(pixelDensity) {
        let newBrushSize = this.brush.size;
        if (newBrushSize === 2) {
            this.brush.image = this.textures.brush_2;
        } else if (newBrushSize === 4) {
            this.brush.image = this.textures.brush_4;
        } else if (newBrushSize === 8) {
            this.brush.image = this.textures.brush_8;
        } else {
            this.brush.image = this.textures.brush_16;
        }
        this.brush.image.loadPixels();
        let brushImagePixels = 4 * (this.brush.image.width * pixelDensity) * (this.brush.image.height * pixelDensity);
        for (let i = 0; i < brushImagePixels; i += 4) {
            this.brush.image.pixels[i + 3] = (this.brush.image.pixels[i + 3] > 0) ? 255 : 0; // * this.brush.image.pixels[i] / 255;
        }
        this.brush.image.updatePixels();
    }

    updateNetworkUserLayer(user, data, x, y) {
        if (user === 'self') {
            this.layers.live.decodeBlock(data, x, y);
            this.layers.live.history = [];
        } else {
            if (this.layers.party[user] === undefined) {
                this.layers.party[user] = new DrawLayer();
            }
            this.layers.party[user].decodeBlock(data, x, y);
        }
        this.order = this.order.filter((id) => id !== user);
        this.order.push(user);
    }

    changeBrush(p5) {
        let { r, g, b, a } = sceneParams.selectedColor;
        this.brush.texture.loadPixels();
        for (let i = 0; i < 16; i++) {
          for (let j = 0; j < 16; j++) {
            this.brush.texture.set(i, j, p5.color(r, g, b, a));
          }
        }
        this.brush.erasing = a === 0;
        this.brush.texture.updatePixels();
        this.brush.texture.mask(this.brush.image);
        this.brush.drop.loadPixels();
        this.brush.drop.set(0, 0, p5.color(r, g, b, a));
        this.brush.drop.updatePixels();
    }

    changeWidth(newWidthBrush) {
        this.brush.size = newWidthBrush;
    }

    setup(p5, assets) {
        this.brush.texture = p5.createImage(16, 16);
        this.layers.live.layers = [];
        this.layers.live.setup(p5);
        this.layers.live.setupNewCanvasState(p5);
        // this.layers.net.setup(p5);
        //this.layers.user.setup(p5);
        this.layers.live.runUpdateCycle(p5);
        this.textures.brush_2 = assets.brush_2;
        this.textures.brush_4 = assets.brush_4;
        this.textures.brush_8 = assets.brush_8;
        this.textures.brush_16 = assets.brush_16;
        this.brush.drop = assets.drop;
    }

    draw(p5) {
        // for (let id of this.order) {
        //     if (id === 'self') {

        //         continue;
        //     }
        //     let layer = this.layers.party[id];
        //     if (layer.needSetup) layer.setup(p5);
        //     layer.draw(p5);
        // }
        // this.layers.net.displayLayersCombined(p5);
        this.layers.live.displayLayersCombined(p5);
    }

}

export { OverlayManager };