import { atom } from 'recoil';

const accountState = atom({
    key: 'accountState',
    default: {
        loggedIn: false,
        address: null,
    },
});

const authState = atom({
    key: 'authState',
    default: {
        session: null,
        roomConnected: false,
        roomId: null,
    },
});


export { accountState, authState } 