import React, { useEffect, useState } from 'react';
import styles from './Room.module.css';
import { accountState, authState } from '../Web3/Auth';
import { storeSession, getSession, removeSession } from '../Web3/Storage';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate, Link } from "react-router-dom";
import { getOwnRoom, createRoom } from '../Network/Api';
 

export default function RoomPage() {
    const account = useRecoilValue(accountState);
    const auth = useRecoilValue(authState);
    const [ ownRoom, setOwnRoom ] = useState(null);
    const navigate = useNavigate();


    useEffect(_ => {
        if(!auth.session || !account.loggedIn) {
            navigate("/", {});
        } else {
            getOwnRoom(auth.session).then(r => {
                setOwnRoom(r)
            }, _ => {})
        }
    }, [auth, account, navigate]);

    return (
        <div className={styles.RoomsPage}>
            <div className={styles.RoomsBackground}>
                {/* <h2>Select room to connect</h2> */}
                {(ownRoom === null) ? ('Loading..') : (<OwnRoomButton room={ownRoom} />)}
                {/* <Link to="/admin">Admin Panel</Link> */}
            </div>
        </div>
    )
}

function OwnRoomButton({ room }) {

    const [ auth, setAuth ] = useRecoilState(authState);
    const account = useRecoilValue(accountState);
    const [ roomCreation, setRoomCreation ] = useState(false);
    const navigate = useNavigate();

    const createRoomCallback = () => {
        setRoomCreation(true);
        createRoom(auth.session).then(r => {
            room.hasRoom = true;
            room.room = {
                id: r.roomId,
                role: 'owner',
                owner: account.address,
            };
        }, _ => {}).finally(_ => setRoomCreation(false));
    };

    useEffect(_=> {
        if (!room.hasRoom && !roomCreation) {
            createRoomCallback();
        } else if (!roomCreation) {
            let redirectTo = '/room/' + room.room.id;
            navigate(redirectTo, {});
        }
    }, [roomCreation, room.hasRoom]);

    return (
        <>
        {(roomCreation) ? 'Loading..' : 'Searching for your room'}
            {/* {(!room.hasRoom) ? (roomCreation) ? 'Loading..' : <button onClick={createRoomCallback}>Create your room</button> : <Link to={'/room/' + room.room.id}>{room.room.name}'s (YOUR) ROOM</Link>} */}
        </>
    )
}
