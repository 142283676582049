import React, { useEffect, useState } from 'react';
import styles from './LoginStyles.module.css';
import { useProviderSetup } from '../Web3/Provider';
import { accountState, authState } from '../Web3/Auth';
import { storeSession, getSession, removeSession } from '../Web3/Storage';
import { useRecoilValue, useRecoilState } from 'recoil';
import { connectToSocket, sendLogin, validateLogin, getNonce } from '../Network/Api';
import { useNavigate, useSearchParams } from "react-router-dom";


export default function LoginPage() {

    const [ setupProvider, signAccount ] = useProviderSetup();
    const [ account, setAccount ] = useRecoilState(accountState);
    const [ auth, setAuth ] = useRecoilState(authState);
    const navigate = useNavigate();
    const [authProcess, setAuthProcess] = useState(false);
    const [someErr, setSomeErr] = useState(null);
    let [searchParams] = useSearchParams();

    useEffect(_ => {
        if(!!auth.session && account.loggedIn) {
            setAuthProcess(false);
            let roomId = searchParams.get('room');
            if (!!roomId) {
                navigate("/room/" + roomId, {});
            } else {
                navigate("/room", {});
            }
        }
    }, [auth, account, navigate, searchParams]);

    useEffect(_ => {
        if(!!account.address && !account.loggedIn) {
            setAuthProcess(true);
            let session = getSession();
            validateLogin(session).then(authPassed => {
                if (!authPassed) {
                    getNonce(account.address).then((r) => {
                        let {nonce, accessKey} = r;
                        signAccount(account.address, nonce, accessKey).then(({security, accessKey}) => {
                            sendLogin(security, accessKey).then(result => {
                                let sess = null;
                                if (!!result['status'] && result['status'] === 'ok') {
                                    sess = result['session'];
                                    storeSession(sess);
                                    setAccount({
                                        ...account,
                                        loggedIn: true,
                                    });
                                    setAuth({
                                        ...auth,
                                        session: sess,
                                    });
                                } else if (!!result['error']) {
                                    console.error(result['error']);
                                    setSomeErr(result['error'].toString());
                                } else {
                                    throw Error('Unknown error')
                                }


                            })
                        }, e => {
                            console.error(e);
                            if (!!e.message) setSomeErr(e.message.toString());
                        })
                    })
                } else {
                    setAccount({
                        ...account,
                        loggedIn: true,
                    });
                    setAuth({
                        ...auth,
                        session,
                    });
                }
            });
        }
    }, [account, setAuth, auth, setAccount]);


    const loginAction = () => {
        setupProvider().then(_ => {}, e => {
            console.error("Login Failed!", e)
        });
    };

    return (account.loggedIn) ? null : (
        <div className={styles.LoginPageHolder}>
            {(someErr === null) ? authProcess ? 'Loading..' : <button className={styles.LoginButton} onClick={loginAction}>Login with Wallet</button> : someErr}
        </div>
    );
}