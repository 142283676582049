import { encodeBlock, decodeBlock } from './Encoder';
import CONN from './Env';


let socket = null;
let openedSocket = false;
let buffer = [];
let dronesOnNet = {};
let layersToUpdate = [];
let updatedPaketLayers = [];
let netPolicies = [];
let needOverlayDelete = false;
let overlayData = null;
let resetLocal = false;

const URL = CONN.URL;

const sendLogin = async (security, accessKey) => {
    let response = await fetch(URL + '/login/', {
        headers: {
          'Accept': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ security, accessKey })
    });
    let result = await response.json();
    return result;
}

const validateLogin = async (session) => {
    let response = await fetch(URL + '/validate/', {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    return result['status'];
}

const getNonce = async (addr) => {
    let response = await fetch(URL + '/nonce/', {
        headers: {
          'Accept': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({ addr })
    });
    let result = await response.json();
    return result;
}

const saveCanvasFinal = async (session, room_id) => {
    let response = await fetch(URL + '/save/' + room_id, {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    return result['status'];
}

const unlockCanvas = async (session, room_id) => {
    let response = await fetch(URL + '/unlock/' + room_id, {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    return result['status'];
}


const isLockedCanvas = async (session, room_id) => {
    let response = await fetch(URL + '/locked/' + room_id, {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    return result['status'];
}

const fetchAdminPreviews = async (session) => {
    let response = await fetch(URL + '/admin/previews', {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    return result;
}


const getOwnRoom = async (session) => {
    let response = await fetch(URL + '/room/', {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'GET',
    });
    let result = await response.json();
    if (!!result['error']) {
        throw Error(result['error'])
    }
    return result;
}

const createRoom = async (session) => {
    let response = await fetch(URL + '/room/', {
        headers: {
          'Accept': 'application/json',
          'Gateway-Session': session,
        },
        method: 'PUT',
    });
    let result = await response.json();
    if (!!result['error']) {
        throw Error(result['error'])
    }
    return result;
}


const sendPointUpdates = (pos, size, color) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "p": pos,
        "c": color,
        "m": size, 
        "t": "p",
    }));
};

const sendBlockUpdate = (p5, layer, maxX, maxY) => {
    if (!openedSocket) return;

    let data = {};
    for (let x = 0; x < maxX; x++) {
        data[x] = {};
        for (let y = 0; y < maxY; y++) {
            data[x][y] = encodeBlock(p5, layer.data[x][y])
        }
    }

    socket.send(JSON.stringify({
        "g": layer.time,
        "d": data,
        "t": "updateLayerv2",
    }));

}

const sendStateUpdate = (p5, layer, maxX, maxY) => {
    if (!openedSocket) return;

    let data = {};
    for (let x = 0; x < maxX; x++) {
        data[x] = {};
        for (let y = 0; y < maxY; y++) {
            data[x][y] = encodeBlock(p5, layer.data[x][y])
        }
    }

    socket.send(JSON.stringify({
        "g": layer.time,
        "d": data,
        "t": "updateStatev2",
    }));

}

const informLayerDeny = (layer, policy) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "g": layer.time,
        "s": policy,
        "t": "updateLayerPolicyv2",
    }));
}

const syncAdmin = (images) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "d": images,
        "t": "syncAdmin",
    }));
}


const layerUpdatev2 = (packet) => {
    updatedPaketLayers.push(packet);
};

const fetchUpdatedPaketLayers = () => {
    let packets = updatedPaketLayers;
    updatedPaketLayers = [];
    return packets;
}

const sendDronePosUpdate = (pos) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "p": pos,
        "t": "d",
    }));
};


const requestHistory = (time) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "g": time,
        "t": "h",
    }));
};


const goForwardNetwork = (pos) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "t": "goForwv2",
    }));
};

const goBackNetwork = (pos) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "t": "goBackv2",
    }));
};

const deleteOverlayNet = () => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "t": "overlayDeletev2",
    }));
};

const updateOverlayNet = (overlay) => {
    if (!openedSocket) return;
    socket.send(JSON.stringify({
        "t": "overlayUpdatev2",
        'd': overlay,
    }));
};

const updNetworkDrones = (d) => {
    dronesOnNet[d['n']] = d['p'];
};

const listDronesOnNet = () => {
    return dronesOnNet;
};

const ffDrawCanvas = (data) => {
    buffer.push(data);
};

const ffFetch = () => {
    let mBuffer = buffer;
    buffer = [];
    return mBuffer;
};

const netLayersUpd = (msg) => {
    layersToUpdate.push(msg);
};

const getUpdatedLayers = () => {
    let lBuffer = layersToUpdate;
    layersToUpdate = [];
    return lBuffer;
};

const updateNetLayerPolicy = (data) => {
    netPolicies.push({
        time: data['g'],
        user: data['a'],
        deny: data['s'],
    });
}

let getNetPolicies = () => {
    let policyBuffer = netPolicies;
    netPolicies = [];
    return policyBuffer;
}

const getOverlayDelete = () => {
    if (needOverlayDelete) {
        needOverlayDelete = false;
        return true;
    }
    return false;
}
    
const getOverlayData = () => {
    if (overlayData === null) return null;
    let tempData = overlayData;
    overlayData = null;
    return tempData;
}

const isResetLocal = () => {
    if (resetLocal) {
        resetLocal = false;
        return true;
    }
    return false;
}


function connectToSocket({ roomId, session, loadedData, fetchAdmin, closed }) {

    socket = new WebSocket(`${CONN.WS}${roomId}?session=${session}`);

    socket.onopen = function(e) {
        console.log("[open] Connection established");
        openedSocket = true;
    };

    socket.onmessage = function(event) {
        let data = JSON.parse(event.data);
        if (data['t'] === 'p') {
            ffDrawCanvas(data);
        } else if (data['t'] === 'd') {
            updNetworkDrones(data);
        } else if (data['t'] === 'stateUpd') {
            netLayersUpd(data);
        } else if (data['t'] === 's') {
            loadedData(data['n']);
        } else if (data['t'] === 'updateLayerv2') {
            layerUpdatev2(data);
        } else if (data['t'] === 'updateLayerPolicyv2') {
            updateNetLayerPolicy(data);
        } else if (data['t'] === 'deleteOverlay') {
            needOverlayDelete = true;
        } else if (data['t'] === 'overlay') {
            overlayData = data['d'];
        } else if (data['t'] === 'clearLocal') {
            resetLocal = true;
        } else if (data['t'] === 'fetchAdmin') {
            fetchAdmin(data['d']);
        }

    };
 
    socket.onclose = function(event) {
        if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
        } else {
            console.log('[close] Connection died');
        }
        closed();
    };

    socket.onerror = function(error) {
        console.log(`[error] ${error.message}`);
    };
}

export {
    connectToSocket,
    sendPointUpdates,
    ffFetch,
    sendDronePosUpdate,
    listDronesOnNet,
    sendBlockUpdate,
    getUpdatedLayers,
    sendLogin,
    validateLogin,
    getOwnRoom,
    createRoom,
    requestHistory,
    fetchUpdatedPaketLayers,
    sendStateUpdate,
    informLayerDeny,
    getNetPolicies,
    fetchAdminPreviews,

    goForwardNetwork,
    goBackNetwork,

    getOverlayDelete,
    getOverlayData,
    deleteOverlayNet,
    updateOverlayNet,
    isResetLocal,

    saveCanvasFinal,
    isLockedCanvas,
    unlockCanvas,
    getNonce,
    syncAdmin,
}