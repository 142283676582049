import React from 'react';
import styles from './Scroller.module.css';


export default function Scroller({ min, max, value, setValue }) {
    return (
        <div className={styles.ScrollView}>
            <input type='range' value={value} onChange={e=>setValue(e.target.value)} className={styles.Zoom} min={min} max={max} />
        </div>
    )
}