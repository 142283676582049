import { useRecoilValue, useRecoilState, atom} from 'recoil';

const currentAdminSelectorState = atom({
    key: 'adminSelector',
    default: {
        used: [],
        selected: null,
        needEdit: null,
    },
    dangerouslyAllowMutability: true,
});

const adminSyncImagesState = atom({
    key: 'adminSync',
    default: {},
    dangerouslyAllowMutability: true,
});

export { currentAdminSelectorState, adminSyncImagesState }