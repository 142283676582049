import React, { useEffect, useState } from 'react';
import {fetchAdminPreviews} from '../Network/Api';
import { useRecoilValue, useRecoilState, atom} from 'recoil';
import { accountState, authState } from '../Web3/Auth';
import styles from './AdminPanel.module.css';
import { decodeCompact } from '../Network/Encoder';
import { currentAdminSelectorState, adminSyncImagesState } from './AdminState.js'
import sceneParams from '../Core/Settings';

function ImageBlock({ img, used, imd }) {

    const [selector, setSelector] = useRecoilState(currentAdminSelectorState);

    return (
        <div className={(!used) ? styles.ImageBlock : styles.ImageBlockUsage} onMouseDown={_ => {
            if (selector.needEdit !== imd) setSelector({ ...selector, selected: imd });
        }}>
            <img src={img} alt='' />
            {(used && selector.needEdit !== imd) ? <button className={styles.ImageButton} onClick={_=>{
                if (selector.needEdit !== imd) setSelector({ ...selector, selected: imd, needEdit: imd })
            }}>EDIT</button>: null}
        </div>
    );

}

export default function AdminPanel() {

    const [auth, setAuth] = useRecoilState(authState);
    const [selector, setSelector] = useRecoilState(currentAdminSelectorState);
    const [_AdminSyncImages, setAdminSyncImages] = useRecoilState(adminSyncImagesState);
    const [images, setImages] = useState(null);

    const runDecoder = (imageObj) => {
        let parts = [];
        for (let x = 0; x < 2; x++) {
            for (let y = 0; y < 2; y++) {
                parts.push(imageObj.data[x][y]);
            }
        }
        let arr = decodeCompact(parts);
        let imgData = new ImageData(arr, 96 * 2);
        var canvas = document.createElement("canvas");
        canvas.width = 96 * 2;
        canvas.height = 96 * 2;
        var ctx = canvas.getContext("2d");
        ctx.putImageData(imgData, 0, 0);
        return canvas.toDataURL("image/png");
    };


    useEffect(_ => {
        if (auth.session !== null) {
            fetchAdminPreviews(auth.session).then(r => {
                setImages(
                    r.images.map(
                        (imgObj, i) => [runDecoder(imgObj), imgObj]
                    )
                );
            });
        }

    }, [auth]);

    
    useEffect(_ => {
        if (!!selector.needDrop && !!images) {
            let adminSyncImages = {};
            images.forEach(([img, imgObj], i) => {
                if (~selector.needDrop.indexOf(imgObj.room)) {
                    adminSyncImages[imgObj.room] = img;
                }
            })
            console.log(adminSyncImages);
            setAdminSyncImages(adminSyncImages);
            setSelector({ ...selector, needDrop: null });
        }

    }, [selector, images, setAdminSyncImages, setSelector]);

    
    return (
        <div className={styles.AdminPanel}>
            <div className={styles.AdminPanel__Content}>
                {
                (images === null) ? 
                <p>Loading Previews..</p> : 
                <>{images.map(([img, imgObj], i) => <ImageBlock key={i} img={img} used={~selector.used.indexOf(imgObj.room)} imd={imgObj.room} />)}</>
                }
            </div>
        </div>
    );
}