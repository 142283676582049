import React, { useEffect, useState } from 'react';
import styles from './Loader.module.css';


export default function Loader({ progress }) {    
    return progress === 1 ? null : (
        <div className={styles.LoaderHolder}>
            <div className={styles.LoaderBar}>
                <div className={styles.Loader} style={{ width: progress * 100 + '%' }}></div>
            </div>
        </div>
    );
}