import sceneParams from '../Core/Settings';

/* Simple Alpha based compression */
const encodeBlock = (p5, image) => {
    let d = p5.pixelDensity();
    image.loadPixels();
    let b = sceneParams.blockSize;
    let packed = {
        a: new Uint8Array(b * b / 8),
        r: null,
        g: null,
        b: null,
    };
    let shift = 0;
    let total = 0;

    let alphaIndex = 0;
    let pixelIndex = image.width * image.height * d * 4;
    for (let i = 0; i <= pixelIndex; i+= 4) {
        if (shift === 8) alphaIndex++;
        shift = (shift === 8) ? 0 : shift;
        let hasAlpha = image.pixels[i + 3] > 0;
        if (hasAlpha) total++;
        if (shift !== 0) packed.a[alphaIndex] <<= 1;
        packed.a[alphaIndex] |= ((hasAlpha) ? 1 : 0);
        shift++;
    }
    packed.r = new Uint8Array(total);
    packed.g = new Uint8Array(total);
    packed.b = new Uint8Array(total);

    let k = 0;
    for (let i = 0; i <= pixelIndex; i += 4) {
        if (image.pixels[i + 3] > 0) {
            packed.r[k] = image.pixels[i];
            packed.g[k] = image.pixels[i + 1];
            packed.b[k] = image.pixels[i + 2];
            k++;
        }
    }

    packed.a = uInt8ToBase64(packed.a);
    packed.r = uInt8ToBase64(packed.r);
    packed.g = uInt8ToBase64(packed.g);
    packed.b = uInt8ToBase64(packed.b);

    return packed;
}

const uInt8ToBase64 = (arr) => {
    let result = '';
    for (let i = 0; i < arr.length; i++) {
      result += String.fromCharCode(arr[i]);
    }
    return btoa(result);
}

const Base64ToUInt8 = (encoded) => {
    const decoded = atob(encoded);
    const result = new Uint8Array(decoded.length);
    for (let i = 0; i < decoded.length; i++) {
        result[i] = decoded.charCodeAt(i);
    }
    return result;
}

const decodeBlock = (p5, image, packed) => {
    let d = p5.pixelDensity();
    image.loadPixels();
    packed.a = Base64ToUInt8(packed.a);
    packed.r = Base64ToUInt8(packed.r);
    packed.g = Base64ToUInt8(packed.g);
    packed.b = Base64ToUInt8(packed.b);

    let shift = 0;
    let k = 0;
    let pixelIndex = image.width * image.height * d * 4;
    let alphaCounter = 0;
    for (let i = 0; i <= pixelIndex; i += 4) {

        if (shift === 8) alphaCounter++;
        let hasAlpha = 0x80 === (packed.a[alphaCounter] & 0x80);
        packed.a[alphaCounter] <<= 1;
        shift = (shift === 8) ? 0 : shift;

        if (hasAlpha) {
            image.pixels[i]     = packed.r[k];
            image.pixels[i + 1] = packed.g[k];
            image.pixels[i + 2] = packed.b[k];
            image.pixels[i + 3] = 255;
            k++;
        } else {
            image.pixels[i + 3] = 0;
        }
        shift++;
    }

    image.updatePixels();
    return image;
}

const decodeCompact = (packedBlocks) => {
    const imageData = new Uint8ClampedArray(4 * 96 * 2 * 96 * 2);
    const parts = [];
    for (let w = 0; w < packedBlocks.length; w++) {
        let packed = packedBlocks[w];
        packed.a = Base64ToUInt8(packed.a);
        packed.r = Base64ToUInt8(packed.r);
        packed.g = Base64ToUInt8(packed.g);
        packed.b = Base64ToUInt8(packed.b);
        let shift = 0;
        let alphaCounter = 0;
        let k = 0;
        let arr = new Uint8ClampedArray(4 * 96 * 96);
        for (let i = 0; i <= arr.length; i += 4) {
            if (shift === 8) alphaCounter++;
            let hasAlpha = 0x80 === (packed.a[alphaCounter] & 0x80);
            packed.a[alphaCounter] <<= 1;
            shift = (shift === 8) ? 0 : shift;
            if (hasAlpha) {
                arr[i]     = packed.r[k];
                arr[i + 1] = packed.g[k];
                arr[i + 2] = packed.b[k];
                arr[i + 3] = 255;
                k++;
            } else {
                arr[i + 3] = 0;
            }
            shift++;
        }
        parts.push(arr);
    }
    for (let i = 0; i < imageData.length; i += 4) {

        let pixel = i / 4;
        let sY = Math.floor(pixel / (96 * 2));
        let sX = pixel % (96 * 2);

        let r = 0;

        if (sY >= 96) {
            // Second half in Y dir
            sY -= 96;
            r = 1;
        }

        if (sX >= 96) { // 0 -> X
            sX -= 96;
            r += 2;
        }

        let j = 4 * (sX + (sY * 96));

        imageData[i]     = parts[r][j];
        imageData[i + 1] = parts[r][j + 1];
        imageData[i + 2] = parts[r][j + 2];
        imageData[i + 3] = parts[r][j + 3];

    }
    return imageData;

}


export { encodeBlock, decodeBlock, decodeCompact, uInt8ToBase64, Base64ToUInt8 }