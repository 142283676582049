
const sceneParams = {
    tiles: {
        x: 2,
        y: 2,
        scale: 96,
    },
    blockSize: 96 * 1,
    zoom: 3,
    maxZoom: 10,
    minZoom: 1,
    position: {
        x: 0,
        y: 0,
    },
    zoomCorrection: {
        x: 0,
        y: 0,
    },
    lastStroke: null,
    poofs: [],
    poofDelay: {
        current: 0,
        max: 8,
    },
    selectedColor: {
        r: 255,
        g: 0,
        b: 0,
        a: 255,
    },
};

export default sceneParams;