


let soundFiles;
let loadingStates = {};
let soundOn = true;


const adjustLoadingState = (p5, name) => {
    console.log("Loaded sound ", name);
    loadingStates[name] = true;
    if (loadingStates.drone && loadingStates.spray) {
        soundSetup(p5);
    }
};

const soundPreload = (p5) => {
    p5.soundFormats('ogg', 'mp3');
    loadingStates = {
        drone: false,
        spray: false,
    };
    soundFiles = {
        drone: p5.loadSound('/assets/SOUND_Drone.mp3', _ => adjustLoadingState(p5, 'drone')),
        spray: p5.loadSound('/assets/SOUND_Spray.mp3', _ => adjustLoadingState(p5, 'spray')),
    };
};

const soundSetup = (p5) => {
    soundFiles.drone.setVolume(0.2);
    soundFiles.drone.loop();
    soundFiles.spray.setVolume(0.2);
};

const playSpraySound = (p5) => {
    if (!loadingStates.spray) return;
    soundFiles.spray.loop();
};

const stopSpraySound = (p5) => {
    if (!loadingStates.spray) return;
    soundFiles.spray.pause();
};

const setDroneSpeedSound = (p5, delta) => {
    if (!loadingStates.drone) return;
    soundFiles.drone.rate(1 + Math.min(delta / 100, 0.2));
};

const muteSounds = (p5) => {
    if (!loadingStates.drone) return;
    if (!loadingStates.spray) return;
    soundFiles.drone.setVolume(soundOn ? 0 : 0.2);
    soundFiles.spray.setVolume(soundOn ? 0 : 0.2);
    soundOn = !soundOn;
};

export {
    soundPreload,
    soundSetup,
    playSpraySound,
    stopSpraySound,
    setDroneSpeedSound,
    muteSounds,
}