import Sketch from "react-p5";
import React, {useState, useEffect} from "react";
import "p5/lib/addons/p5.sound";
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useRecoilValue, useRecoilState, selector } from 'recoil';
import { currentAdminSelectorState, adminSyncImagesState } from './Pages/AdminState.js'

import sceneParams from './Core/Settings';
import { soundPreload, soundSetup, stopSpraySound, playSpraySound, setDroneSpeedSound, muteSounds } from './Core/Sound';
import { accountState, authState } from './Web3/Auth';
import { sendPointUpdates, ffFetch, sendDronePosUpdate, listDronesOnNet, sendBlockUpdate, connectToSocket, getUpdatedLayers, fetchUpdatedPaketLayers, getNetPolicies,     
  getOverlayDelete,
  getOverlayData,
  deleteOverlayNet,
  updateOverlayNet,
  isResetLocal,
  saveCanvasFinal,
  isLockedCanvas,
  syncAdmin,
  unlockCanvas,
} from './Network/Api';
import {uInt8ToBase64, Base64ToUInt8} from './Network/Encoder';
import { imageDroppingState } from './Core/ImageDrop';
import { OverlayManager } from './Core/Draw';
import Scroller from './Pages/Scroller';

let needOverlaySend = false;
let needReload = false;
let needBake = false;
let overlayTransp = 0.5;
let assets = {};
let mousePressedEv = false;
let allowDrag = false;
let skewRace = {
  d1: false,
  d2: false,
  d3: false,
  d4: false,
};
let possibleZoom = null;
let frame = 0;
let pixelDensity;
let adminMode = false;

let overlaySize;
let possibleTopImage;
let topImagePos = {
  x: 0,
  y: 0,
};
let firstScale = {
  width: 0,
  height: 0,
}

let brushChanged = true;
let locked = true;


let adminPanel = {};
let adminAwaiting = null;

let zoomTo;
let initialState = true;

let prevDx, prevDy;

let topImageOverlay = null;

let isManipulating = false;
let isManipulatingPos = false;
let canDraw = true;

let stats = {
  tilesVisible: 0,
};

let admynSyncImg = {};

const asset_links = {
  bricks:  '/assets/WhiteBricks_Tile.png',
  drone:   '/assets/Drone_0000.png',
  drone_1: '/assets/Drone_0001.png',
  brush_16: '/assets/Brush_16px.png',
  brush_8: '/assets/Brush_8px.png',
  brush_4: '/assets/Brush_4px.png',
  brush_2: '/assets/Brush_2px.png',
  logo:    '/assets/MClogo.png',
  cursor:  '/assets/cursor.png',
  background: '/assets/Grid_v4.gif',
  drop:   '/assets/drop.png',
};

let animationKeyLinks = {
  poof: [],
};

const padKeyZeros = (num) => {
  var s = "0000" + num;
  return s.substr(s.length-4);
};

for (let j = 0; j < 16; j++) {
  animationKeyLinks.poof.push(`/assets/poof/Poof_${padKeyZeros(j)}.png`);
}

const adjustZoomPriority = (newZoom) => {
  possibleZoom = newZoom;
}


const renderPerFrameAnimation = (p5, animationAsset, kIndex, color, posX, posY) => {
  let kImage = animationAsset[kIndex];
  let kColorChannel = p5.createImage(kImage.width, kImage.height);
  kColorChannel.loadPixels();
  for (let i = 0; i < kImage.width; i++) {
    for (let j = 0; j < kImage.height; j++) {
      kColorChannel.set(i, j, color);
    }
  }
  kImage.loadPixels();
  let d = p5.pixelDensity();
  let halfImage = 4 * (kImage.width * d) * (kImage.height * d);
  for (let i = 0; i < halfImage; i += 4) {
    let opacityCol = kImage.pixels[i + 3] * kImage.pixels[i] / 255;
    kColorChannel.pixels[i + 3] = opacityCol * 1.2;// kImage.pixels[i + 3]; //opacityCol;
    let colorMul = (180 - opacityCol) / 180;
    if (colorMul !== 1) {
      kColorChannel.pixels[i]     = Math.min(255, kColorChannel.pixels[i]     + (200 * colorMul));
      kColorChannel.pixels[i + 1] = Math.min(255, kColorChannel.pixels[i + 1] + (200 * colorMul));
      kColorChannel.pixels[i + 2] = Math.min(255, kColorChannel.pixels[i + 2] + (200 * colorMul));
    }

  }
  kImage.updatePixels();
  kColorChannel.updatePixels();
  kColorChannel.mask(kImage);
  p5.noSmooth();
  p5.image(kColorChannel, posX - (kColorChannel.width / 2), posY - (kColorChannel.height / 2));
};

const calculateTileLimits = (p5, deltaSize) => {
  let xMin = Math.floor(Math.max(0, (-sceneParams.position.x / sceneParams.zoom) / deltaSize));
  let yMin = Math.floor(Math.max(0, (-sceneParams.position.y / sceneParams.zoom) / deltaSize));
  let xMax = Math.min(sceneParams.tiles.x * sceneParams.tiles.scale / deltaSize, xMin + 1 + (p5.windowWidth  / sceneParams.zoom / deltaSize));
  let yMax = Math.min(sceneParams.tiles.y * sceneParams.tiles.scale / deltaSize, yMin + 1 + (p5.windowHeight / sceneParams.zoom / deltaSize));
  return { xMin, yMin, xMax, yMax };
};

const drawWall = (p5) => {
  if (sceneParams.zoom < 1) {
    p5.strokeWeight(2/sceneParams.zoom);
    p5.stroke(0);
    p5.fill(255);
    p5.rect((sceneParams.position.x / sceneParams.zoom), sceneParams.position.y / sceneParams.zoom, sceneParams.tiles.scale * sceneParams.tiles.x, sceneParams.tiles.scale * sceneParams.tiles.y);
    p5.noStroke();

  } else {
    let { xMin, yMin, xMax, yMax } = calculateTileLimits(p5, sceneParams.tiles.scale);
    for (let x = xMin; x < xMax; x++) {
      for (let y = yMin; y < yMax; y++) {

        let px = x * sceneParams.tiles.scale + (sceneParams.position.x / sceneParams.zoom);
        let py = y * sceneParams.tiles.scale + (sceneParams.position.y / sceneParams.zoom);

        p5.noSmooth();
        p5.image(assets.bricks, px, py, sceneParams.tiles.scale, sceneParams.tiles.scale);

      }
    }
  }
};

const movePosChecked = (p5, mX, mY) => {


  let isBreaking = { x: false, y: false };

  if (p5.windowWidth > (sceneParams.tiles.x * sceneParams.tiles.scale * sceneParams.zoom)) {
    sceneParams.position.x = p5.windowWidth / 2 - (sceneParams.tiles.x * sceneParams.tiles.scale * sceneParams.zoom) / 2;
    isBreaking.x = true;
  }

  if (p5.windowHeight > (sceneParams.tiles.y * sceneParams.tiles.scale * sceneParams.zoom)) {
    sceneParams.position.y = p5.windowHeight / 2 - (sceneParams.tiles.y * sceneParams.tiles.scale * sceneParams.zoom) / 2;
    isBreaking.y = true;
  }

  if (!isBreaking.y) {
    if (mY < 0) {
      sceneParams.position.y = mY;
    } else {
      sceneParams.position.y = 0;
    }
    if (mY < p5.windowHeight - (sceneParams.tiles.y * sceneParams.tiles.scale * sceneParams.zoom)) {
      sceneParams.position.y = p5.windowHeight - (sceneParams.tiles.y * sceneParams.tiles.scale * sceneParams.zoom);
    }
  }

  if (!isBreaking.x) {
    if (mX < 0) {
      sceneParams.position.x = mX;
    } else {
      sceneParams.position.x = 0;
    }
    if (mX < p5.windowWidth - (sceneParams.tiles.x * sceneParams.tiles.scale * sceneParams.zoom)) {
      sceneParams.position.x = p5.windowWidth - (sceneParams.tiles.x * sceneParams.tiles.scale * sceneParams.zoom);
    }
  }

};

const adjustZoom = (p5, newZoom) => {
  if ( newZoom < sceneParams.maxZoom && newZoom > sceneParams.minZoom) {
    // && newZoom > sceneParams.minZoom
    let newZoomedCorrectionPosX = ((p5.mouseX - sceneParams.position.x) / sceneParams.zoom) - ((p5.mouseX - sceneParams.position.x) / newZoom);
    let newZoomedCorrectionPosY = ((p5.mouseY - sceneParams.position.y) / sceneParams.zoom) - ((p5.mouseY - sceneParams.position.y) / newZoom);

    sceneParams.position.x -= newZoomedCorrectionPosX * newZoom;
    sceneParams.position.y -= newZoomedCorrectionPosY * newZoom;

    sceneParams.zoom = newZoom;
  }
};

const adjustNoZoom = (p5, newZoom) => {
  if ( newZoom < sceneParams.maxZoom && newZoom > sceneParams.minZoom) {
    let newZoomedCorrectionPosX = ((p5.windowWidth/2 - sceneParams.position.x) / sceneParams.zoom) -  ((p5.windowWidth/2 - sceneParams.position.x) / newZoom);
    let newZoomedCorrectionPosY = ((p5.windowHeight/2 - sceneParams.position.y) / sceneParams.zoom) - ((p5.windowHeight/2 - sceneParams.position.y) / newZoom);

    sceneParams.position.x -= newZoomedCorrectionPosX * newZoom;
    sceneParams.position.y -= newZoomedCorrectionPosY * newZoom;
    sceneParams.zoom = newZoom;
  }
};



const adjustMaxZoom = (p5) => {
  //sceneParams.minZoom = Math.max(p5.windowWidth / (sceneParams.tiles.x * sceneParams.tiles.scale), sceneParams.minZoom) / 2;
};


const poofRenderer = (p5) => {
  sceneParams.poofs = sceneParams.poofs.filter(p => p.kf !== 16);
  for (let poofObject of sceneParams.poofs) {
    renderPerFrameAnimation(p5, assets.poof, poofObject.kf, poofObject.color, poofObject.x + sceneParams.position.x, poofObject.y + sceneParams.position.y);
    poofObject.kf++;
  }
};

const keyboardMove = (p5) => {
  if (initialState) return;

  let deltaPos = 10;
  let newX = sceneParams.position.x, newY = sceneParams.position.y;

  if (p5.keyIsDown(38)) {
    newY += deltaPos;
  } else if (p5.keyIsDown(40)) {
    newY -= deltaPos;
  } else if (p5.keyIsDown(37)) {
    newX += deltaPos;
  } else if (p5.keyIsDown(39)) {
    newX -= deltaPos;
  }
  
  movePosChecked(p5, newX, newY);

};

const keyboardZoom = (p5) => {
  const deltaZoom = 0.1;
  if (initialState) return;

  if (p5.keyIsDown(109)) {
    // Minus on numpad
    adjustZoom(p5, sceneParams.zoom - deltaZoom);
  } else if (p5.keyIsDown(107)) {
    // Plus on numpad
    adjustZoom(p5, sceneParams.zoom + deltaZoom);
  }
};

const drawDebugInfo = (p5) => {
  p5.scale(1/sceneParams.zoom);
  p5.strokeWeight(2);
  p5.stroke(255);
  p5.textSize(14);
  //p5.fill(p5.color(255));

  let textFields = [
    'DEBUG WINDOW:',
    p5.deltaTime + ' ms draw time',
    Math.round(p5.frameRate()) + '/60 fps',
    stats.tilesVisible + ' tiles drawn (' + sceneParams.blockSize * stats.tilesVisible + 'px)',
    'Hold numpad +/- or scroll to zoom at exact position',
    'Press Spacebar to loop zoom positions',
    'Press arrow keys for movement or drag mouse holding middle/right click',
    'Press S to mute sounds',
  ];

  let ik = 1;

  for (let field of textFields) {
    p5.text(field, 10, 20 * ik);
    ik++;
  }
  
};


const loadImagesOnCanvas = (files) => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (!file.type.startsWith('image/')){ continue }

    possibleTopImage = URL.createObjectURL(file);
    // console.log(possibleTopImage);
  }
};



var dataURLToBlob = function(dataURL)
{
    var BASE64_MARKER = ";base64,";
    if (dataURL.indexOf(BASE64_MARKER) == -1)
    {
        var parts = dataURL.split(",");
        var contentType = parts[0].split(":")[1];
        var raw = decodeURIComponent(parts[1]);

        return new Blob([raw], {type: contentType});
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1].split('\n')[0]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
}

const loadAdminTop = (fileUrl) => {
  possibleTopImage = URL.createObjectURL(dataURLToBlob(fileUrl));
}
// const changeBrushSize = (newBrushSize) => {
//   if (newBrushSize === 2) {
//     brushImage = assets.brush_2;
//   } else if (newBrushSize === 4) {
//     brushImage = assets.brush_4;
//   } else {
//     brushImage = assets.brush_8;
//   }
//   brushSize = newBrushSize;
//   brushImage.loadPixels();
//   let brushImagePixels = 4 * (brushImage.width * pixelDensity) * (brushImage.height * pixelDensity);
//   for (let i = 0; i < brushImagePixels; i += 4) {
//     brushImage.pixels[i + 3] = brushImage.pixels[i + 3] * brushImage.pixels[i] / 255;
//   }
//   brushImage.updatePixels();
// };

const setSelectedColor = (c) => {
  sceneParams.selectedColor = c;
};

// const changeWidth = (newWidthBrush) => {
//   maxWidth = newWidthBrush;
//   changeBrushSize(maxWidth);
// };

const setInitialState = (st) => {
  initialState = st;
};






class Canvas extends React.Component {

  // document.firstElementChild.style.zoom = "reset";

  constructor({ setProgress, setCallbacks }) {
    super();
    this.invokeProgress = _ => {
      setProgress(++this.currentProgress / this.progressTotal);
    }
    this.drawLayersContext = new OverlayManager();
    setCallbacks({
      setWidth: w => this.drawLayersContext.changeWidth(w),
      goBack: _ => this.drawLayersContext.goBack(),
      goFor: _ => this.drawLayersContext.goFor(),
    });
  }

  preload = (p5) => {
    
    this.progressTotal = 1 + Object.keys(asset_links).length;
    for (let key in animationKeyLinks) {
      this.progressTotal += animationKeyLinks[key].length;
    }
    this.currentProgress = 0;

    pixelDensity = p5.pixelDensity();
    // kColorBrushChannel = p5.createImage(8, 8);
    soundPreload(p5);
    
    this.invokeProgress();

    for (let key in asset_links) {
      assets[key] = p5.loadImage(asset_links[key], _ => this.invokeProgress());
    }

    for (let key in animationKeyLinks) {
      assets[key] = new Array(animationKeyLinks[key].length);
      for (let j = 0; j < animationKeyLinks[key].length; j++) {
        p5.loadImage(animationKeyLinks[key][j], img => {
          assets[key][j] = img;
          this.invokeProgress();
        });
      }
    }
  }
  
  keyPressed = (p5) => {
    if (p5.keyIsDown(83)) {
      muteSounds(p5);
    } else if (p5.keyIsDown(32) && !initialState) {
      isManipulatingPos = !isManipulatingPos;
      //zoomTo = (zoomTo === null || zoomTo > 3) ? 3 : (zoomTo === 3) ? sceneParams.minZoom : sceneParams.maxZoom;
    }
  };

  setup = (p5, canvasParentRef) => {
    this.preload(p5);
		p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef);
    p5.noStroke();
    p5.noCursor();

    this.drawLayersContext.setup(p5, assets);
    this.drawLayersContext.changeBrushSize(2);
    this.drawLayersContext.changeBrush(p5);

    // setupTiles(p5);
    adjustMaxZoom(p5);
    sceneParams.position = {
      x: p5.windowWidth / 2  - (sceneParams.tiles.x * sceneParams.tiles.scale) * 1.5,
      y: p5.windowHeight / 2 - (sceneParams.tiles.y * sceneParams.tiles.scale) * 1.5,
    }


    // TODO

    // setInterval( () => {
    //   for (let x = 0; x < sceneParams.tiles.x * sceneParams.tiles.scale / sceneParams.blockSize; x++) {
    //     for (let y = 0; y < sceneParams.tiles.y * sceneParams.tiles.scale / sceneParams.blockSize; y++) {
    //       if (updatedBlock[x][y]) {
    //         sendBlockUpdate(p5, tilingPool[x][y], x, y);
    //         updatedBlock[x][y] = false;
    //       }
    //     }
    //   }
    // }, 3000);

	};

  draw = (p5) => {
    if (needReload) {
      this.drawLayersContext.setup(p5, assets);
      needReload = false;
    }
    if (this.currentProgress !== this.progressTotal) return;

    frame++;
    sceneParams.poofDelay.current++;

    keyboardZoom(p5);
    keyboardMove(p5);


    if (!!possibleZoom) {
      adjustNoZoom(p5, possibleZoom);
    }
    possibleZoom = null;


    if (isResetLocal()) {
      this.drawLayersContext.resetLocal(p5);
    }
    

    let buf = ffFetch();

    // TODO: Network clients
    // for (let d of buf) {
    //   let bC = {};
    //   Object.assign(bC, sceneParams.selectedColor);
    //   sceneParams.selectedColor = d["c"];
    //   let bBs = brushSize;
    //   changeBrushSize(d["m"]);
    //   changeBrush(p5);
    //   drawOnCanvas(p5, d["p"]);
    //   changeBrushSize(bBs);
    //   sceneParams.selectedColor = bC;
    // }

    let needDeleteOv = getOverlayDelete();
    if (needDeleteOv) {
      topImageOverlay = null;
    }

    let overlNet = getOverlayData();
    if (overlNet !== null) {
      topImagePos = overlNet.position;
      overlaySize = overlNet.scale;
      overlayTransp = overlNet.opacity;
      topImageOverlay = p5.createImage(overlNet.image.width, overlNet.image.height);
      topImageOverlay.loadPixels();
      let newPixels = Base64ToUInt8(overlNet.image.data);
      // console.log(newPixels.length, topImageOverlay.pixels.length);
      for (let i = 0; i < topImageOverlay.pixels.length; i++) {
        topImageOverlay.pixels[i] = newPixels[i];
      }
      topImageOverlay.updatePixels();
      // console.log(topImageOverlay.pixels);
      // for (let i = 0; i < topImageOverlay.width; i++) {
      //   for (let j = 0; j < topImageOverlay.height; j++) {
      //     let [r, g, b, a] = overlNet.image.data[i][j];
      //     topImageOverlay.set(i, j, p5.color(r, g, b, a));
      //   }
      // }
      
    }
    

    let layersToUpdate = getUpdatedLayers();
    if (layersToUpdate.length > 0) {
      this.drawLayersContext.netLayerInsert(
        p5,
        layersToUpdate[layersToUpdate.length - 1]
      );
    }

    let policies = getNetPolicies();
    for (let policy of policies) {
      this.drawLayersContext.netPolicyUpdate(policy);
    }
    // for (let layer of layersToUpdate) {
    //   if (!!layer.g) {
    //     this.drawLayersContext.history_cursor = layer.g;
    //   }
    //   this.drawLayersContext.updateNetworkUserLayer(
    //     layer.a, layer.d, layer.x, layer.y
    //   );
    // }

    let topLayers = fetchUpdatedPaketLayers();
    for (let layer of topLayers) {
      this.drawLayersContext.pushTopLayer(p5, layer, layer['a']);
    }

    if (brushChanged) {
      this.drawLayersContext.changeBrushSize(pixelDensity);
      this.drawLayersContext.changeBrush(p5);
      brushChanged = false;
    }


    if (mousePressedEv && frame % 8 === 0) {
      this.drawLayersContext.drawStrokeLocal(p5);
    }

    let scale = sceneParams.zoom;
    p5.clear();
    p5.scale(scale);

    if (!initialState) {
      p5.background(0);
      p5.scale(1/scale);

      let sY = p5.windowHeight;
      let sX = p5.windowHeight * assets.background.width / assets.background.height;

      if (p5.windowWidth / p5.windowHeight > assets.background.width / assets.background.height) {
        sY = p5.windowWidth * assets.background.height / assets.background.width;
        sX = p5.windowWidth;
      }
      
      p5.noSmooth();
      p5.image(assets.background, (p5.windowWidth - sX)/2, (p5.windowHeight - sY)/2, sX, sY);

      p5.scale(scale);
      drawWall(p5);
    } else {
      p5.image(assets.logo, p5.windowWidth / (2 * scale) - 100 / scale, p5.windowHeight / (2 * scale) - 100 / scale, 200 / scale, 32 / scale);
    }
  
    if (!!possibleTopImage) {
        let maxResolution = 1920 * 1080;
       p5.loadImage(possibleTopImage, img => {
        if (img.width * img.height <= maxResolution) {
          let imgWidth = Math.min(img.width, sceneParams.tiles.x * sceneParams.tiles.scale / 2);
          // img.loadPixels();
          // for (let j = 3; j < img.width * pixelDensity * img.height * 4 * pixelDensity; j += 4) {
          //   img.pixels[j] *= overlayTransp;
          // }
          // img.updatePixels();
          firstScale = {
            width: imgWidth,
            height: imgWidth * img.height / img.width,
          };
          topImageOverlay = img;
          overlaySize = {
            width: imgWidth,
            height: imgWidth * img.height / img.width,
          };
          // topImageOverlay.resize(imgWidth, imgWidth * img.height / img.width);
          possibleTopImage = null;
          isManipulating = true;
        }
      });
    }

    if (needOverlaySend) {
      let overlNet = {};
      overlNet.pos = topImagePos;
      overlNet.s = overlaySize;
      overlNet.a = overlayTransp;
      overlNet.img = {};
      overlNet.img.width = topImageOverlay.width;
      overlNet.img.height = topImageOverlay.height;
      topImageOverlay.loadPixels();
      overlNet.img.data = uInt8ToBase64(topImageOverlay.pixels);
      // topImageOverlay.loadPixels();
      // for (let i = 0; i < topImageOverlay.width; i++) {
      //   overlNet.img.data[i] = {};
      //   for (let j = 0; j < topImageOverlay.height; j++) {
      //     let ind = ((topImageOverlay.width * i) + j) * 4;
      //     let [r, g, b, a] = [
      //       topImageOverlay.pixels[ind],
      //       topImageOverlay.pixels[ind + 1],
      //       topImageOverlay.pixels[ind + 2],
      //       topImageOverlay.pixels[ind + 3],
      //     ];
      //     overlNet.img.data[i][j] = [r, g, b, a];
      //   }
      // }
      updateOverlayNet(overlNet);
      needOverlaySend = false;
    }

    if (needBake && topImageOverlay) {
      p5.noSmooth();
      this.drawLayersContext.bake(
        p5, topImageOverlay, 
        (topImagePos.x), 
        (topImagePos.y), 
        overlaySize.width, overlaySize.height
      );
      topImageOverlay = null;
      needBake = false;

    }

    if (adminMode) {
      this.drawLayersContext.draw(p5);
    }


    for (let imgId in adminPanel) {

      if (!!adminPanel[imgId].img) {
        let scPosX = ((sceneParams.position.x) / scale + adminPanel[imgId].pos.x);
        let scPosY = ((sceneParams.position.y) / scale + adminPanel[imgId].pos.y);
        p5.noSmooth();
        p5.image(
          adminPanel[imgId].img, 
          scPosX,
          scPosY,
          2 * 96, 2 * 96
        );
      } else if(Object.keys(admynSyncImg).includes(imgId)) {
        let imgLoad = admynSyncImg[imgId];
        p5.loadImage(imgLoad, img => {
          adminPanel[imgId].img = img;
        });
        delete admynSyncImg[imgId];
      }

    }

    if (!!topImageOverlay) {

      
      let scPosX = ((sceneParams.position.x) / scale + topImagePos.x);
      let scPosY = ((sceneParams.position.y) / scale + topImagePos.y);



      p5.noSmooth();
      p5.tint(255, 255 * overlayTransp);
      p5.image(
        topImageOverlay, 
        scPosX,// - (topImageOverlay.width  / 16), 
        scPosY,// - (topImageOverlay.height / 16), 
        overlaySize.width, overlaySize.height
      );
      p5.noTint();

      p5.fill(0);
      if (isManipulating) {
        let wD = 4;
        let wDH = wD / 2;
        p5.rect(scPosX - wDH, scPosY - wDH, wD, wD);
        p5.rect(scPosX + overlaySize.width - wDH, scPosY + overlaySize.height - wDH, wD, wD);
        p5.rect(scPosX + overlaySize.width - wDH, scPosY - wDH, wD, wD);
        p5.rect(scPosX - wDH, scPosY + overlaySize.height - wDH, wD, wD);
      }



      // p5.rect(scPosX + overlaySize.width - wDH, scPosY - wDH, wD, wD);
      // p5.rect(scPosX - wDH, scPosY + overlaySize.height - wDH, wD, wD);
      
      // p5.fill(5);
      // p5.noStroke();
      // p5.rect((sceneParams.position.x - 10) / scale, (sceneParams.position.y - 10) / scale, 20 / scale, 20 / scale);
      // p5.rect((sceneParams.position.x - 10) / scale + topImageOverlay.width, (sceneParams.position.y - 10) / scale, 20 / scale, 20 / scale);
      // p5.rect((sceneParams.position.x - 10) / scale, (sceneParams.position.y - 10) / scale + topImageOverlay.height, 20 / scale, 20 / scale);
      // p5.rect((sceneParams.position.x - 10) / scale + topImageOverlay.width, (sceneParams.position.y - 10) / scale + topImageOverlay.height, 20 / scale, 20 / scale);
    } 

    if (!adminMode) {
      this.drawLayersContext.draw(p5);
    }


    //drawTiles(p5);
    poofRenderer(p5);


    let dx = p5.mouseX / scale - (assets.drone.width / 2);
    let dy = p5.mouseY / scale - (assets.drone.height / 2);
    let drone_asset = (frame % 8 > 4) ? assets.drone : assets.drone_1;
    //let droneCursor = (frame % 8 > 4) ? '/assets/Drone_0000.png' : '/assets/Drone_0001.png';
    p5.noSmooth();
    if (!isManipulating && !isManipulatingPos && !adminMode && !locked) {
      p5.noCursor();
      // p5.cursor('/assets/Drone_0000.png');
      p5.image(drone_asset, dx - 0.5, dy + 5);
      p5.fill(150);
      p5.noStroke();
      p5.rect((p5.mouseX - 2) / scale , (p5.mouseY - 2) / scale, 4 / scale, 4 / scale );
    } else if (!isManipulating && !isManipulatingPos && adminMode) {
      p5.cursor('default');
    } else {
      p5.cursor('grab');
    }
    

    

    let objDronePoses = listDronesOnNet();
    p5.textSize(4);
    p5.textAlign(p5.CENTER);
    for (let w3Addr in objDronePoses) {
      p5.noSmooth();
      let nP = objDronePoses[w3Addr];
      let nDx = nP.x - (assets.drone.width / 2) + (sceneParams.position.x / scale);
      let nDy =  nP.y - (assets.drone.height / 2) + (sceneParams.position.y / scale)
      p5.image(drone_asset, nDx, nDy);

      p5.textFont('Fiery Turk');


      p5.fill(0);
      p5.textStyle(p5.BOLD);
      p5.text(w3Addr.split('-')[0], nDx + (assets.drone.width / 2), nDy + (assets.drone.height / 2) - 16);

      p5.fill(255);
      p5.textStyle(p5.NORMAL);
      p5.text(w3Addr.split('-')[0], nDx + (assets.drone.width / 2), nDy + (assets.drone.height / 2) - 16);

    }


    let px = dx - (sceneParams.position.x / scale);
    let py = dy - (sceneParams.position.y / scale);

    let spDelta = 0;
    if (!!prevDy && !!prevDx) {
      spDelta = Math.abs(px - prevDx) + Math.abs(py - prevDy);
    }
    
    prevDy = py;
    prevDx = px;

    if (spDelta !== 0) {
      sendDronePosUpdate({ x: (p5.mouseX - sceneParams.position.x) / sceneParams.zoom, y: (p5.mouseY - sceneParams.position.y) / sceneParams.zoom });
    }
    

    setDroneSpeedSound(p5, spDelta);

    // drawNetworkDrones(p5, dx, dy);

    // drawDebugInfo(p5); 

    if (!!zoomTo && Math.abs(zoomTo - scale) >= 0.05) {
      if (zoomTo < scale) {
        adjustZoom(p5, sceneParams.zoom - 0.05);
      } else {
        adjustZoom(p5, sceneParams.zoom + 0.05);
      }
    }

	};

  mousePressed = (p5, ev) => {
    if (locked && !adminMode) return;
    if (ev.target.localName !== 'canvas') return;
    if (ev.buttons === 1 && (p5.windowHeight - p5.mouseY) > 60) {
      if (!isManipulating && !isManipulatingPos && canDraw) {
        playSpraySound(p5);
        this.drawLayersContext.drawStrokeLocal(p5);
        mousePressedEv = true;
      } else if (!!topImageOverlay) {
        let mX = (p5.mouseX - sceneParams.position.x) / sceneParams.zoom;
        let mY = (p5.mouseY - sceneParams.position.y) / sceneParams.zoom;
        allowDrag = (
          topImagePos.x < mX && mX < topImagePos.x + overlaySize.width &&
          topImagePos.y < mY && mY < topImagePos.y + overlaySize.height
        );
        let d1 = Math.sqrt(Math.pow((topImagePos.x - mX), 2) +  Math.pow((topImagePos.y - mY), 2));
        let d2 = Math.sqrt(Math.pow((topImagePos.x + overlaySize.width - mX), 2) +  Math.pow((topImagePos.y + overlaySize.height - mY), 2));
        let d3 = Math.sqrt(Math.pow((topImagePos.x - mX), 2) +  Math.pow((topImagePos.y + overlaySize.height - mY), 2));
        let d4 = Math.sqrt(Math.pow((topImagePos.x + overlaySize.width - mX), 2) +  Math.pow((topImagePos.y - mY), 2));
        let repDist = 10;
        skewRace = {
          d1: !adminMode && d1 < repDist,
          d2: !adminMode && d2 < repDist,
          d3: !adminMode && d3 < repDist,
          d4: !adminMode && d4 < repDist,
        }
        // topImagePos = {
        //   x: (p5.mouseX - sceneParams.position.x) / sceneParams.zoom - overlaySize.width/2,
        //   y: (p5.mouseY - sceneParams.position.y) / sceneParams.zoom - overlaySize.height/2,
        // }
      }
    }
  };

  mouseReleased = (p5, ev) => {
    if (locked && !adminMode) return;
    stopSpraySound(p5);
    mousePressedEv = false;
    this.drawLayersContext.layers.live.readyReSend = true;
    this.drawLayersContext.nullPrevPosCur();
  };

  mouseDragged = (p5, ev) => {
    if (locked && !adminMode) return;
    if (ev.target.localName !== 'canvas') return;
    if (ev.buttons === 1 && (p5.windowHeight - p5.mouseY) > 60) {
      if(isManipulatingPos) {
        let newX = sceneParams.position.x + ev.movementX;
        let newY = sceneParams.position.y + ev.movementY;
        movePosChecked(p5, newX, newY);
      } else if (!isManipulating && canDraw) {
        this.drawLayersContext.drawStrokeLocal(p5);
      } else if (!!topImageOverlay) {
        let nX = Math.round(topImagePos.x + (ev.movementX / sceneParams.zoom));
        let nY = Math.round(topImagePos.y + (ev.movementY / sceneParams.zoom));

        if (allowDrag && !skewRace.d1 && !skewRace.d2 && !skewRace.d3 && !skewRace.d4) {
          topImagePos = {
            x: nX,
            y: nY,
          }
        }

        let fX = ev.movementX / sceneParams.zoom;
        let fY = ev.movementY / sceneParams.zoom;
        let higX = Math.abs(fX) > Math.abs(fY);


        if (skewRace.d1) {
          let scX = (overlaySize.width - fX);
          let scY = (overlaySize.height - fY);
          if (higX) {
            scY = overlaySize.height * scX /  overlaySize.width;
          } else {
            scX = overlaySize.width * scY /  overlaySize.height;
          }
          topImagePos.y += overlaySize.height - scY;
          topImagePos.x += overlaySize.width - scX;
          overlaySize = { width: scX, height: scY };
        }

        if (skewRace.d2) {
          let scX = (overlaySize.width  + fX);
          let scY = (overlaySize.height + fY);
          if (higX) {
            scY = overlaySize.height * scX /  overlaySize.width;
          } else {
            scX = overlaySize.width * scY /  overlaySize.height;
          }
          // topImagePos.y += overlaySize.height - scY;
          // topImagePos.x += overlaySize.width - scX;
          overlaySize = { width: scX, height: scY };
        }

        if (skewRace.d3) {
          
          let scX = (overlaySize.width  - fX);
          let scY = (overlaySize.height + fY);
          if (higX) {
            scY = overlaySize.height * scX /  overlaySize.width;
          } else {
            scX = overlaySize.width * scY /  overlaySize.height;
          }
          //topImagePos.y += overlaySize.height - scY;
          topImagePos.x += overlaySize.width - scX;
          overlaySize = { width: scX, height: scY };
        }

        if (skewRace.d4) {
          
          let scX = (overlaySize.width  + fX);
          let scY = (overlaySize.height - fY);
          if (higX) {
            scY = overlaySize.height * scX /  overlaySize.width;
          } else {
            scX = overlaySize.width * scY /  overlaySize.height;
          }
          topImagePos.y += overlaySize.height - scY;
          //topImagePos.x += overlaySize.width - scX;
          overlaySize = { width: scX, height: scY };
          
        }

      }
    } else if (!initialState && (p5.windowHeight - p5.mouseY) > 60) {
      let newX = sceneParams.position.x + ev.movementX;
      let newY = sceneParams.position.y + ev.movementY;
      movePosChecked(p5, newX, newY);
    }
  } 

  mouseWheel = (p5, ev) => {
    if (initialState) return;
    let newZoom = sceneParams.zoom - (ev.delta / 510.0);
    adjustZoom(p5, newZoom);
    zoomTo = null;
  }

  windowResized = (p5, ev) => {
    p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    adjustMaxZoom(p5);
  }

  render() {
    return (
      <>
      <DropperContext>
        <Sketch keyPressed={this.keyPressed} className="canvas" setup={this.setup} draw={this.draw} mouseReleased={this.mouseReleased} mousePressed={this.mousePressed} mouseDragged={this.mouseDragged} mouseWheel={this.mouseWheel} windowResized={this.windowResized} />
      </DropperContext>
      </>
    )  
  }
}

const DropperContext = ({ children }) => {
  const [_, setImage] = useRecoilState(imageDroppingState);

  const handleCanvasDrop = (e) => {
    e.stopPropagation();
    e.preventDefault(); 
    topImagePos = {
      x: (e.clientX - sceneParams.position.x) / sceneParams.zoom,
      y: (e.clientY - sceneParams.position.y) / sceneParams.zoom,
    }
    let dt = e.dataTransfer;
    setImage(true);
    if (adminMode) {
      dt.items[0].getAsString(r => loadAdminTop(r));
    } else {
      loadImagesOnCanvas(dt.files);
    }

  };

  const handleDragenter = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDragover = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className="canvasHolder" onDrop={handleCanvasDrop} onDragEnter={handleDragenter} onDragOver={handleDragover}>{children}</div>
  )
}


function UIPanel({ changeWidth, goBack, goFor, admin }) {

  let { roomId } = useParams();
  const [selectedColor, setColor] = useState("#ff0000");
  const [editingImage, setEditingImage] = useRecoilState(imageDroppingState);
  const [imageScale, setImageScale] = useState(50);
  const [auth, setAuth] = useRecoilState(authState);
  const account = useRecoilValue(accountState);
  const navigate = useNavigate();
  const [internalZoom, setInternalZoom] = useState(sceneParams.zoom * 100);
  const [isConnecting, setIsConnecting] = useState(false);
  const [owner, setOwner] = useState("SOMEONE");
  const [isLocked, setLocked] = useState(false);
  const [selectorImage, setSelector] = useRecoilState(currentAdminSelectorState);
  const [adminSyncImages, setAdminSyncImages] = useRecoilState(adminSyncImagesState);

  const adminRoomId = "meta--room";

  useEffect(_ => {
    adminAwaiting = selectorImage.selected;
    if (!!selectorImage.needEdit) {
      isManipulating = true;
      topImageOverlay = adminPanel[selectorImage.needEdit].img;
      topImagePos = adminPanel[selectorImage.needEdit].pos;
      setEditingImage(true);
      overlaySize = {
        width: topImageOverlay.width,
        height: topImageOverlay.height,
      };
      delete adminPanel[selectorImage.needEdit];
    }
  }, [selectorImage, setEditingImage]);

  useEffect(_ => {
    if ((!auth.session || !account.loggedIn) && admin) {
      navigate("/", {});
      return;
    }
    if (roomId === undefined && !admin) {
      navigate("/", {});
      return;
    }
    if(!auth.session || !account.loggedIn) {
        navigate("/?room=" + roomId, {});
        return;
    } else if (!auth.roomConnected) return;
    setInitialState(false);
  }, [auth, account, navigate, roomId, admin]);

  useEffect(_ => {
    //console.log(adminSyncImages);
    admynSyncImg = adminSyncImages;
    // for (let key in adminSyncImages) {
    //   fakeDropImageAdmin(adminSyncImages[key]);
    // }
  }, [adminSyncImages]);
  

  useEffect(_ => {
    const fetchAdmin = (images) => {
      let used = [];
      for (let image of images) {
        used.push(image.id);
        adminPanel[image.id] = { img: null, pos: image.pos };
      }
      setSelector({ selected: null, used, needEdit: null, needDrop: used });
    };
    const loadedData = (canvas_owner) => {
      setAuth({ ...auth, roomConnected: true });
      setIsConnecting(false);
      setOwner(canvas_owner);
    };
    if (!!auth && account.loggedIn && !!auth.session && (!!roomId || admin) && !auth.roomConnected) {
      setIsConnecting(true);
      if (!admin) {
        locked = true;
        isLockedCanvas(auth.session, roomId).then(l => {
          locked = l;
          setLocked(l);
        });
      } else {
        locked = false;
        setLocked(false);
      }
      const closed = _ => {
        navigate("/", {});
      };
      connectToSocket({ roomId: (admin) ? adminRoomId : roomId, session: auth.session, loadedData, fetchAdmin, closed });
    }
  }, [account, auth, roomId, setAuth, admin, setSelector]);


  useEffect(_ => {
    if (admin) {
      // needReload = true;
      sceneParams.tiles.x = 10;
      sceneParams.tiles.y = 10;
      overlayTransp = 1;
      needReload = true;
    } else {
      // needReload = true;
      overlayTransp = 0.5;
      sceneParams.tiles.x = 2;
      sceneParams.tiles.y = 2;
    }
    canDraw = !admin;
    adminMode = admin;
  }, [admin]);

  // const getZoom = () => sceneParams.zoom;

  const adjustInputColor = (e) => {
    let newColor = e.target.value;
    setColor(newColor);
    var m = newColor.match(/^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i);
    setSelectedColor({
        r: parseInt(m[1], 16),
        g: parseInt(m[2], 16),
        b: parseInt(m[3], 16),
        a: 255,
    });
    brushChanged = true;
  };

  const setEraser = _ => {
    setSelectedColor({
      r: 0,
      g: 0,
      b: 0,
      a: 0,
  });
  };

  const handleScaleImage = (e) => {
    let sc = +(e.target.value) / 100;
    overlayTransp = sc;
    setImageScale(e.target.value);
  };

  const updateImagePos = () => {
    let images = [];
    for (let imgU in adminPanel) {
      images.push({
        'id': imgU,
        'pos': adminPanel[imgU].pos,
      });
    }
    syncAdmin(images);
  }

  if (isLocked) {

    return (
      <>
        <div className="uipanel__top red_banner">CANVAS LOCKED</div>
        <div className="uipanel">{
              (owner === "YOUR") ? (<button className="widthButton" onClick={_ => {
                unlockCanvas(auth.session, roomId).then(s => {
                  if (s === "ok") {
                    locked = false;
                    setLocked(false);
                  }
                });
              }}>UNLOCK CANVAS</button>) : null
        }</div>
      </>
    )
  }

  return (isConnecting) ? <div className="uipanel__full">Fetching the canvas..</div> : (!auth.roomConnected) ? null : (editingImage) ? (
    <div className={(admin) ? "uipanel admin-panel" : "uipanel"}>
      <button className="widthButton" onClick={_ => {
        setEditingImage(false);
        // topImageOverlay.resize(firstScale.width * (+imageScale / 100), firstScale.height * (+imageScale / 100));
        isManipulating = false;
        if (admin) {
          // needBake = true;
          adminPanel[adminAwaiting] = { img: topImageOverlay, pos: topImagePos };
          topImageOverlay = null;
          possibleTopImage = null;
          let used = selectorImage.used;
          used.push(selectorImage.selected);
          updateImagePos();
          setSelector({ ...selectorImage, selected: null, used, needEdit: null });
        } else {
          needOverlaySend = true;
        }
      }}>DONE</button>
      {(admin) ? null : <input className="opacityRange" type="range" min={0} value={imageScale} max={100} step={1} onChange={handleScaleImage} />}
      <button onClick={_=>{
        setEditingImage(false);
        isManipulating = false;
        topImageOverlay = null;
        possibleTopImage = null;
        deleteOverlayNet();
        let used = selectorImage.used;
        used.splice(used.indexOf(selectorImage.selected), 1);
        updateImagePos();
        setSelector({ ...selectorImage, selected: null, used, needEdit: null });
      }}>DELETE</button>
    </div>
  ) : (admin) ? <div className="uipanel__top">
    {/* <Link to='/'>Back Home</Link> */}
    {owner} META CANVAS
  </div> : (
    <>
      <div className="uipanel__top">{owner} META CANVAS<br />
       {/* <Link to='/'>Back Home</Link> */}
      </div>
      <div className="uipanel">
        <button className="doButton" onClick={_ => goBack()  }><img src='/assets/undo.png' /></button>
        <input type="color" value={selectedColor} onChange={adjustInputColor} />
        {/* <button className="widthButton" onClick={setEraser}>Eraser</button> */}
        <button className="widthButton" onClick={_ => {
          changeWidth(2);
          brushChanged = true;
        }}>2 px </button>
        <button className="widthButton" onClick={_ => {
          changeWidth(4);
          brushChanged = true;
        }}>4 px </button>
        <button className="widthButton" onClick={_ => {
          changeWidth(8);
          brushChanged = true;
        }}>8 px</button>
        <button className="widthButton" onClick={_ => {
          changeWidth(16);
          brushChanged = true;
        }}>16 px</button>
        {(owner === "YOUR") ? (<button className="widthButton" onClick={_ => {
          saveCanvasFinal(auth.session, roomId);
          locked = true;
          setLocked(true);
        }}>FINAL SAVE</button>) : null}
        {(topImageOverlay !== null) ? (<button onClick={_=>{setEditingImage(true);isManipulating = true;}}>PICTURE</button>) : null}
        <button className="doButton" onClick={_ => goFor()  }><img src='/assets/redo.png' /></button>
      </div>
      <Scroller min={sceneParams.minZoom*100} max={sceneParams.maxZoom*100} step={1} value={internalZoom} setValue={z => {
        adjustZoomPriority(z/100);
        setInternalZoom(z);
      }} />
    </>
  )
}

export { Canvas, UIPanel };