    import WalletConnectProvider from "@walletconnect/web3-provider";
    import Web3 from "web3";
    import { providers, utils } from "ethers";
    import Web3Modal from "web3modal";
    import { accountState } from './Auth';
    import { atom, useRecoilState } from 'recoil';
    import { useEffect } from "react";


    const providerOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: "0abfac0d1f354185903fcdd9d98b96aa"
            }
        }
    };

    const web3ModalState = atom({
        key: 'web3Modal',
        default: new Web3Modal({
            network: "mainnet",
            cacheProvider: true,
            providerOptions
        }),
        dangerouslyAllowMutability: true,
    });


    const web3State = atom({
        key: 'web3',
        default: null,
        dangerouslyAllowMutability: true,
    });

    function useProviderSetup() {

        const [_, setAccount] = useRecoilState(accountState);
        const [web3Modal]     = useRecoilState(web3ModalState);
        const [web3, setWeb3] = useRecoilState(web3State);

        const resetAccount = () => {
            setAccount({
                loggedIn: false,
                address: null,
            });
        };

        useEffect(() => {
            if (web3 === null) return;
            web3.eth.getAccounts().then(accountList => {
                setAccount({
                    loggedIn: false,
                    address: accountList[0]
                });
            });
        }, [web3, setAccount]);

        const signAccount = async ( account, nonce, accessKey ) => {
            const msgParams = [
                {
                    type: 'string',     
                    name: 'code',    
                    value: nonce.toString(), 
                },
            ];
    
            // Sign nonce

            let security = await web3.currentProvider.request({
                method: 'eth_signTypedData',
                params: [msgParams, account],
                from: account,
            });

            // let c1 = utils.keccak256(utils.toUtf8Bytes('string code'));
            // let c2 = utils.keccak256(utils.toUtf8Bytes(nonce.toString()));
            // console.log(c1, new Uint8Array([...utils.arrayify(c1), ...utils.arrayify(c2)]));
            // const security = await web3.eth.sign(
            //     utils.keccak256(new Uint8Array([...utils.arrayify(c1), ...utils.arrayify(c2)])), account
            // );
    
            return { security, accessKey }
    
        };

        const enableProvider = async () => {

            const provider = await web3Modal.connect();

            provider.on("accountsChanged", (accounts) => {
                if (accounts.length === 0) {
                    resetAccount();
                    return;
                } 
                setAccount({
                    loggedIn: false,
                    address: accounts[0],
                });
            });

            provider.on("chainChanged", (chainId) => {
                if ('0x1' !== chainId) {
                    resetAccount();
                }
            });

            provider.on("disconnect", (error) => {
                resetAccount();
            });

            // await provider.request({ method: 'eth_requestAccounts' });
            
            const web3__init = new Web3(provider);
            // let acc = web3__init.eth.accounts[0];
            // web3__init.eth.signTypedData();
            setWeb3(web3__init);

            // return acc;

        };

        return [ enableProvider, signAccount ];
    }


    export { useProviderSetup } 